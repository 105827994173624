.storeSelectCard {
  width: 100%;
}

.storePlaceholder {
  padding: 4px 11px;
}

.storeSelectDiv {
  width: 100%;
  min-height: 32px;
}
.storeTags {
  width: 100%;
  min-height: 100%;
  max-height: 100px;
  padding: 2px 4px;
  overflow-y: auto;
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}

.selectCard {
  display: flex;
  height: 460px;
}

.selectCardItem {
  height: 100%;
  width: 50%;
  border-radius: 10px;
  margin: 4px 6px;
}
.selectTreeSelect {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 384px;
}

.selectTableCard {
  height: 436px;
  padding: 4px 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
