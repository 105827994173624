.selectContentCard {
  border-radius: 8px;
  height: 364px;
  padding: 4px;
  width: 100%;
  display: flex;
  overflow-x: auto;
}
.selectNoData {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectSpin {
  width: max-content;
  height: max-content;
  margin: auto;
}
.checkboxesDiv {
  height: 100%;
  max-width: 200px;
  min-width: 150px;
  min-height: 354px;
  display: flex;
  overflow-y: auto;
}
.checkboxDivider {
  border-left: 2px solid rgba(0, 0, 0, 0.06);
}

.checkboxesItems {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.checkboxItem {
  margin: 2px;
  padding: 4px;
  min-height: 60px;
  min-width: 140px;
  width: 95%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}
.checkboxItemLabel {
  flex: 1;
  min-width: 100px;
  padding-left: 8px;
  display: flex;
  align-items: center;
}
.checkboxItemLabelSpan {
  flex: 1;
  margin: 0;
}
