.selectCard {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 480px;
  min-width: 900px;
}

.selectCardItem {
  height: 100%;
  width: 50%;
  border-radius: 10px;
  margin: 4px 6px;
}

.selectTableCard {
  height: 415px;
  padding: 4px 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.selectedTableCard {
  height: 476px;
  padding: 4px 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
