.storeSelectCard {
  width: 100%;
}

.storePlaceholder {
  padding: 4px 11px;
}

.storeSelectDiv {
  width: 100%;
  min-height: 32px;
}
.storeTags {
  width: 100%;
  min-height: 100%;
  max-height: 100px;
  padding: 2px 4px;
  overflow-y: auto;
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}
