.proLayoutDiv {
  width: 100%;
}
.logo {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
  }

  img {
    display: inline-block;
    height: 32px;
    vertical-align: middle;
  }

  h1 {
    display: inline-block;
    height: 32px;
    margin: 0 0 0 12px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    vertical-align: middle;
    animation: pro-layout-title-hide 0.3s;
  }
}

@keyframes pro-layout-title-hide {
  0% {
    display: none;
    opacity: 0;
  }
  80% {
    display: none;
    opacity: 0;
  }
  100% {
    display: unset;
    opacity: 1;
  }
}
